import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Head from "../components/head"
import Header from "../components/header"
import Nav from "../components/nav"
import styles from "../styles/global.css"
import clients from "../styles/clients.css"
import media from "../styles/media.css"
import Footer from "../components/footer"
import { LoginHeader } from "../components/nav"
import { SocialHeader } from "../components/social"

export function Banner_img() {
  return <StaticImage src="../../static/images/banner4.jpg" alt="banner" />
}
export default () => (
  <>
    <Header titleText="Our Clients - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <LoginHeader />
        <SocialHeader />
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a className="current_menu" rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
      </div>
      <Head />

      <div className="banner banner_clients">
        <Banner_img />
        <div className="banner_text">
          <div className="bannerinfo">
            AFFORDABLE FINANCIAL SERVICES
            <br /> FOR ALL
          </div>
          <ul className="loginbox">
            <li>
              <a
                rel=""
                href="https://calendly.com/kate-hao_happy-mango/happy-mango-30-minute-online-demo"
                className="banks-get-a-demo"
              >
                Demo
              </a>
            </li>
            <li>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=0"
                className="banks-try-for-free"
              >
                Trial
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="media_list">
        <ul>
          <li>
            <a rel="" href="/media/">
              <img
                alt="americanbanker"
                src="/sites/default/files/americanbanker1.jpg"
              />{" "}
            </a>
          </li>
          <li>
            <a rel="" href="/media/">
              <img alt="usatoday" src="/sites/default/files/usatoday.jpg" />{" "}
            </a>
          </li>
          <li>
            <a rel="" href="/media/">
              <img
                alt="credit-union-times"
                src="/sites/default/files/credit-union-times1.jpg"
              />{" "}
            </a>
          </li>
        </ul>
      </div>

      <div className="clients_div">
        <div id="clients_top" className="div_height"></div>
        <div className="clients_top">
          <h1>HELPING OUR CLIENTS SERVE THEIR COMMUNITIES</h1>
          <div className="content">
            Select a category below to learn more about inventory management,
            selling with Square, and how our retail POS will work with you.
          </div>
        </div>
        <ul className="clients_list">
          <li>
            <label htmlFor="input_checkbox_clients_list1">
              <div className="li_son">
                <h1>BANKS</h1>
                <p>
                  Use Happy Mango to improve risk management and drive down the
                  costs of small dollar lending
                </p>
                <a rel="" href="javascript:;">
                  Learn more
                </a>
                <img alt="Bank SpringBank" src="/images/Bank_SpringBank.png" />
                <input
                  id="input_checkbox_clients_list1"
                  className="checkbox_input"
                  type="checkbox"
                />
                <div className="list_bg"></div>
                <div className="clients_content">
                  <p>
                    Spring Bank is an FDIC insured community bank headquartered
                    in South Bronx, a “banking desert.” By working with Happy
                    Mango, Spring Bank launched a unique small dollar loan
                    program without the requirements of credit scores. Through
                    this program, thousands were able to access affordable loans
                    and improve their credit as well as savings while paying off
                    the loans.{" "}
                  </p>
                  <span> Back</span>
                </div>
              </div>
            </label>
          </li>
          <li>
            <label htmlFor="input_checkbox_clients_list2">
              <div className="li_son">
                <h1>CREDIT UNIONS</h1>
                <p>
                  Use Happy Mango to expand membership while improving operating
                  efficiency
                </p>
                <label>
                  <a rel="" href="javascript:;">
                    Learn more
                  </a>
                </label>
                <img
                  alt="CreditUnion NTFCU"
                  src="/images/CreditUnion_NTFCU.png"
                />
                <input
                  id="input_checkbox_clients_list2"
                  className="checkbox_input"
                  type="checkbox"
                />
                <div className="list_bg"></div>
                <div className="clients_content">
                  <p>
                    Neighborhood Trust Federal Credit Union serves the
                    Washington Heights community, where the hit movie “In the
                    Heights” was based. By working with Happy Mango, NTFCU moved
                    its account opening and loan processing services, in both
                    English and Spanish, online. This saved its members and
                    staff time, increased transaction volume as well as
                    membership.{" "}
                  </p>
                  <span> Back</span>
                </div>
              </div>
            </label>
          </li>
          <li>
            <label htmlFor="input_checkbox_clients_list3">
              <div className="li_son">
                <h1>CDFIS</h1>
                <p>
                  Use Happy Mango to deepen relationships in the community and
                  launch niche financial products
                </p>
                <label>
                  <a rel="" href="javascript:;">
                    Learn more
                  </a>
                </label>
                <img alt="CDFI IRCCEO" src="/images/CDFI_IRCCEO.png" />
                <input
                  id="input_checkbox_clients_list3"
                  className="checkbox_input"
                  type="checkbox"
                />
                <div className="list_bg"></div>
                <div className="clients_content">
                  <p>
                    The International Rescue Committee, founded in 1933 at the
                    request of Albert Einstein, specializes in refugee
                    resettlement among other global initiatives. Using Happy
                    Mango, the Center for Economic Opportunity (IRC-CEO) built
                    on a national network of financial counselors to extend
                    special loan products for new immigrants to access and build
                    credit.{" "}
                  </p>
                  <span> Back</span>
                </div>
              </div>
            </label>
          </li>
        </ul>
      </div>

      <div className="pricing">
        <h1>PRICING PLANS</h1>
        <ul className="price_ul">
          <li className="price_li">
            <div className="price_li_div"></div>
            <ul>
              <li>Number of Operating Licenses</li>
              <li>New Customers per Month</li>
              <li>New Applications per Month</li>
              <li>Total Amount Lent per Month</li>
            </ul>
          </li>
          <li className="price_li">
            <div className="price_li_div">
              <h2>Basic</h2>
              <div className="title_price">
                $350 per Month
                <br />
                $2,500 One - Time Set Up Fee
              </div>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=1"
                className="banks_freetrial"
              >
                Try for Free
              </a>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=1"
                className="banks_purchase display_none"
              >
                Purchase Now
              </a>
            </div>
            <ul>
              <li>Up to 2</li>
              <li>Unlimited</li>
              <li>Up to 40</li>
              <li>Up to $200,000</li>
            </ul>
          </li>
          <li className="price_li">
            <div className="price_li_div">
              <h2>Plus</h2>
              <div className="title_price">
                $650 per Month
                <br />
                $4,000 One - Time Set Up Fee
              </div>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=2"
                className="banks_freetrial"
              >
                Try for Free
              </a>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=2"
                className="banks_purchase display_none"
              >
                Purchase Now
              </a>
            </div>
            <ul>
              <li>Up to 3</li>
              <li>Unlimited</li>
              <li>Up to 80</li>
              <li>Up to $400,000</li>
            </ul>
          </li>

          <li className="price_li">
            <div className="price_li_div">
              <h2>Mas</h2>
              <div className="title_price">
                $950/Month
                <br />
                $5,000 One - Time Set Up Fee
              </div>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=3"
                className="banks_freetrial"
              >
                Try for Free
              </a>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=3"
                className="banks_purchase display_none"
              >
                Purchase Now
              </a>
            </div>
            <ul>
              <li>Up to 5</li>
              <li>Unlimited</li>
              <li>Up to 120</li>
              <li>Up to $600,000</li>
            </ul>
          </li>
          <li className="price_li">
            <div className="price_li_div">
              <h2>Tailored</h2>
              <div className="title_price">
                <a rel="" href="tel:+12129711381;" className="banks_purchase">
                  Call Us (212) 971-1381
                </a>
                <br />
                <a
                  rel=""
                  href="mailto:contact@happymangocredit.com"
                  className="banks_purchase"
                >
                  Email Us
                </a>
              </div>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=4"
                className="banks_freetrial"
              >
                Try for Free
              </a>
            </div>
            <ul>
              <li>As Needed</li>
              <li>Unlimited</li>
              <li>150 or More</li>
              <li>Unlimited</li>
            </ul>
          </li>
        </ul>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  </>
)
